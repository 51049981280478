@import "src/css/global";

.container {
	display: flex;
	
	.column {
		flex: 0 1 33%;
		
		&:last-child {
			margin-right: 0;
		}
		
		.row {
			h3 {
				font-family: "GreycliffCF", sans-serif;
				margin-bottom: 30px;
			}
			
			a {
				display: block;
				font-weight: $light;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
			
			&:first-child:not(:last-child),
			&:last-child:not(:first-child) {
				height: 50%;
			}
		}
	}
}

// PC
@media (min-width: 1024px) {
	.container {
		padding-top: 165px;
		padding-bottom: 190px;
		
		.column {
			flex: 0 1 33%;
			margin-right: 15px;
			
			.row {
				h3 {
					font-size: 40px;
					margin-bottom: 30px;
				}
				
				a {
					font-size: 18px;
					margin-bottom: 15px;
				}
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.container {
		padding: 95px 0;
		
		.column {
			flex: 0 1 33%;
			margin-right: tablet(60px);
			
			.row {
				h3 {
					font-size: tablet(40px);
					margin-bottom: tablet(45px);
				}
				
				a {
					font-size: tablet(16px);
					margin-bottom: tablet(15px);
				}
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.container {
		padding: 45px 0;
		flex-wrap: wrap;
		
		.column {
			flex: 0 1 50%;
			
			.row {
				height: auto !important;
				
				&:not(:only-child) {
					margin-bottom: 45px;
				}
				
				h3 {
					font-size: mobile(20px);
					margin-bottom: mobile(22.5px);
				}
				
				a {
					font-size: mobile(13px);
					margin-bottom: mobile(13px);
				}
			}
			
		}
	}
}