@import "src/css/global";

#ProductItemContainer {
	display: flex;
	overflow-x: hidden;
	scrollbar-width: none;
	
	&::-webkit-scrollbar {
		width: 0;
	}
}