@import "src/css/global";

.container {
	display: flex;
	justify-content: space-between;
	
	.text {
		flex: 0 1 50%;
	}
	
	img {
		flex: 0 1 45%;
	}
	
	.text {
		.title {
			font-family: "Poppins", sans-serif;
			font-weight: $bold;
			line-height: 90%;
		}
		
		.subTitle, .text {
			font-weight: $light;
		}
		
		.subTitle {
			line-height: 163%;
			
			&:first-child {
				margin-bottom: 0;
			}
		}
		
		.text {
			line-height: 200%;
			margin-right: 0;
		}
		
		.sign {
			font-weight: $light;
			
			b {
				font-weight: $bold;
				letter-spacing: 7.5px;
				font-size: inherit;
			}
		}
	}
}



// PC
@media (min-width: 1024px) {
	:global #Greeting article > div {
		max-width: 1920px;
	}
	
	.container {
		padding: 150px 0;
		
		.text {
			margin-right: 150px;
			
			.title {
				font-size: 50px;
				margin-bottom: 70px;
			}
			
			.subTitle {
				font-size: 21px;
				margin-bottom: 30px;
			}
			
			.text {
				font-size: 15px;
				margin-bottom: 70px;
			}
			
			.sign {
				font-size: 21px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.container {
		flex-direction: column-reverse;
		padding: tablet(85px) tablet(50px);
		
		.text {
			margin-top: tablet(65px);
			
			.title {
				font-size: tablet(50px);
				margin-bottom: tablet(85px);
			}
			
			.subTitle {
				font-size: tablet(21px);
				margin-bottom: tablet(30px);
			}
			
			.text {
				font-size: tablet(16px);
				margin-bottom: tablet(65px);
			}
			
			.sign {
				font-size: tablet(30px);
			}
		}
		
		img {
			width: 100%;
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.container {
		flex-direction: column-reverse;
		padding: mobile(45px) mobile(25px);
		
		.text {
			margin-top: mobile(30px);
			
			.title {
				font-size: mobile(30px);
				margin-bottom: mobile(30px);
			}
			
			.subTitle {
				font-size: mobile(14px);
				margin-bottom: mobile(16px);
			}
			
			.text {
				font-size: mobile(12px);
				margin-bottom: mobile(30px);
			}
			
			.sign {
				font-size: mobile(14px);
			}
		}
		
		img {
			width: 100%;
		}
	}
}