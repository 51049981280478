/*
 * 이곳에는 전역 변수 및 전역 설정이 들어가 있습니다.
 * 컴포넌트 css의 수정은 /component 폴더에서 확인하세요.
 * 컴포넌트 신규 추가시, @import "../../global.scss" 를 추가해주세요.
 */

// GreycliffCF
// 굵음 굵기
@font-face {
	font-family: "GreycliffCF";
	font-display: swap;
	font-weight: 700;
	src: url('../fonts/GreycliffCF/GreycliffCF_Bold_M.woff2') format('woff2'),
	url('../fonts/GreycliffCF/GreycliffCF_Bold_M.woff') format('woff'),
	url('../fonts/GreycliffCF/GreycliffCF_Bold_M.otf') format('otf');
}

// font Weight Setting
$light : 300;
$normal : 400;
$bold : 700;

// 성연 인더스트리 팔레트 (컬러)
$sungyun-red: rgb(255, 126, 85);
$sungyun-orange: rgb(255, 193, 0);
$sungyun-yellow: rgb(220, 255, 44);

$sungyun-green: rgb(100, 224, 194);
$sungyun-blue: rgb(116, 224, 226);
$sungyun-purple: rgb(255, 115, 169);

// 성연 인더스트리 팔레트 (블랙)
$sungyun-indigo: rgb(45, 52, 60);
$sungyun-gray: rgb(114, 123, 132);
$sungyun-lightgray: rgb(190, 190, 190);

// global variable
$content-max : 894px;
@function deUnit($value) {
	@return ($value / ($value * 0 + 1))
}

@function tablet($size) {
	@return (deUnit($size) / 7.68) * 1vw;
}

@function mobile($size) {
	@return (deUnit($size) / 3.75) * 1vw;
}