@import "src/css/global";

$border-color: #A8ACAF;

.form {
	.inputContainer {
		input {
			border: solid 1px $border-color;
			background: transparent;
			font-weight: $light;
			
			&[disabled] {
				background-color: $sungyun-lightgray;
			}
		}
		
		textarea {
			border: solid 1px $border-color;
			background: transparent;
			resize: none;
			font-weight: $light;
			width: 100%;
		}
		
		.inputRow {
			display: flex;
			align-items: center;
			border-bottom: solid 1px $border-color;
			
			label {
				font-weight: bold;
				
				b {
					font-size: inherit;
					color: $sungyun-red;
				}
			}
			
			&:first-child {
				border-top: solid 2px black;
			}
		}
		
		.titleRow {
			display: flex;
			
			.selectWrapper {
				border-top: solid 2px black;
				position: relative;
				
				select {
					background: none;
					border-radius: 0;
					width: 100%;
					appearance: none;
					font-weight: $bold;
					border-color: transparent transparent $border-color transparent;
				}
				
				&:before {
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-10%);
					border: solid 5px;
					border-color: #333 transparent transparent;
				}
			}
			
			input {
				border-top: solid 2px;
				border-color: black transparent $border-color transparent;
			}
		}
	}
	
	.btnContainer {
		display: flex;
		justify-content: center;
		
		.submitBtn {
			background: transparent;
			border: solid 1px #A8ACAF;
			border-radius: 5px;
			color: #A8ACAF;
			cursor: pointer;
		}
	}
}

// PC
@media (min-width: 1024px) {
	.form {
		padding: 65px 0 75px 0;
		
		.inputContainer {
			margin-bottom: 50px;
			
			input {
				padding: 12px;
				font-size: 16px;
			}
			
			textarea {
				height: 525px;
				padding: 20px;
				font-size: 16px;
			}
			
			.inputRow {
				padding: 20px 0;
				
				label {
					padding-left: 20px;
					flex: 0 0 280px;
				}
				
				input {
					flex: 0 1 360px;
				}
			}
			
			.titleRow {
				margin-bottom: 20px;
				
				.selectWrapper {
					flex: 0 1 240px;
					margin-right: 40px;
					
					select {
						padding: 12px 20px;
					}
					
					&:before {
						right: 20px;
					}
				}
				
				input {
					flex: 0 1 100%;
				}
			}
		}
		
		.btnContainer {
			.submitBtn {
				font-size: 18px;
				padding: 20px 30px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.form {
		padding: tablet(65px) 0 tablet(80px) 0;
		
		.inputContainer {
			margin-bottom: tablet(45px);
			
			input {
				padding: tablet(12px);
				font-size: tablet(18px);
			}
			
			textarea {
				height: tablet(400px);
				padding: tablet(40px) tablet(25px);
				font-size: tablet(16px);
			}
			
			.inputRow {
				justify-content: space-between;
				padding: tablet(20px);
				
				label {
					flex: 0 0 tablet(240px);
					font-size: tablet(18px);
					
					&.labelCaptcha {
						flex: 0 1 auto;
					}
				}
				
				input {
					flex: 0 1 100%;
				}
			}
			
			.titleRow {
				flex-direction: column;
				margin-bottom: tablet(20px);
				padding: 0 tablet(40px);
				
				.selectWrapper {
					border-top: solid 1px $border-color;
					margin-bottom: tablet(20px);
					
					select {
						font-size: tablet(18px);
						padding: tablet(12px) tablet(20px);
					}
					
					&:before {
						right: tablet(20px);
					}
				}
				
				input {
					border-top: solid 1px $border-color;
					width: 100%;
					font-size: tablet(18px);
				}
			}
		}
		
		.btnContainer {
			.submitBtn {
				font-size: 18px;
				padding: tablet(25px) tablet(120px);
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.form {
		padding: mobile(45px) 0 mobile(60px) 0;
		
		.inputContainer {
			margin-bottom: mobile(25px);
			
			input {
				padding: mobile(12px);
				font-size: mobile(13.05px);
			}
			
			textarea {
				height: mobile(525px);
				padding: mobile(40px) mobile(25px);
				font-size: mobile(16px);
			}
			
			.inputRow {
				padding: mobile(12.5px);
				
				label {
					flex: 0 0 mobile(100px);
					font-size: mobile(13.05px);
				}
				
				input {
					flex: 0 1 100%;
				}
			}
			
			.titleRow {
				flex-direction: column;
				margin-bottom: mobile(20px);
				
				.selectWrapper {
					border-top: solid 1px $border-color;
					margin-bottom: mobile(20px);
					
					select {
						font-size: mobile(13px);
						padding: mobile(12px) mobile(12.5px);
						font-weight: $normal;
					}
					
					&:before {
						right: mobile(20px);
					}
				}
				
				input {
					border-top: solid 1px $border-color;
					width: 100%;
					font-size: mobile(13px);
				}
			}
			
			.optionRow {
				flex-direction: column;
				
				label {
					width: 100%;
					text-align: left;
					flex: 0;
					margin-bottom: 20px;
				}
				
				input {
					flex: 0;
					width: 100%;
				}
				
				.recaptcha {
					transform: translateY(-9px) scale(0.8);
				}
			}
		}
		
		.btnContainer {
			.submitBtn {
				font-size: 18px;
				padding: mobile(20px) mobile(60px);
			}
		}
	}
}