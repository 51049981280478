@import "src/css/global";

.currentRoute {
	padding-top: 500px;
	position: relative;
	color: white;
	width: 100%;
	background: rgba(black, .4);
	
	img {
		position: fixed;
		top: 0;
		height: 800px;
		width: 100%;
		object-fit: cover;
		z-index: -1;
	}
	
	h1 {
		font-family: "GreycliffCF", sans-serif;
		font-weight: $bold;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		height: 375px;
		
		h1 {
			font-size: 72px;
		}
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		height: 565px;
		
		h1 {
			font-size: 9.375vw;
		}
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		height: 255px;
		
		h1 {
			font-size: 10.13vw;
		}
	}
}

.article {
	background-color: white;
	
	.container {
		max-width: $content-max;
		margin: 0 auto;
		
		// PC
		@media (min-width: 1024px) {
			padding: 0 53px;
		}
		
		// Tablet
		@media (max-width: 1023px) and (min-width: 426px) {
			padding: 0 40px;
		}
		
		// Mobile
		@media (max-width: 425px) {
			padding: 0 20px;
		}
	}
}