@import "src/css/global";

.inputRow {
	display: flex;
	align-items: center;
	border-bottom: solid 1px #93929B;
	
	label {
		font-weight: $bold;
	}
	
	input {
		border: solid 1px #6C7177;
		background-color: transparent;
		font-weight: $light;
		
		&[disabled] {
			color: black;
			border: solid 1px transparent;
			padding-left: 0;
		}
	}
}

// PC
@media (min-width: 1024px) {
	.inputRow {
		padding: 20px;
		
		label {
			flex: 0 1 280px;
			font-size: 16px;
		}
		
		input {
			flex: 0 1 100%;
			min-width: 185px;
			padding: 15px;
			font-size: 16px;
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.inputRow {
		padding: tablet(20px);
		
		label {
			flex: 0 1 tablet(230px);
			font-size: tablet(18px);
		}
		
		input {
			flex: 0 1 calc(100% - 230px);
			padding: tablet(15px);
			font-size: tablet(18px);
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.inputRow {
		padding: mobile(12.5px);
		
		label {
			flex: 0 1 mobile(120px);
			font-size: mobile(13.05px);
		}
		
		input {
			flex: 0 1 calc(100% - 120px);
			padding: mobile(12px);
			font-size: mobile(13.05px);
		}
	}
}