html {
	white-space: normal;
	scrollbar-width: none;
	overflow-x: hidden;
	
	&::-webkit-scrollbar {
		width: 0;
	}
}

body {
	white-space: normal;
	background-color: white;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	font-size: 13px;
	white-space: pre-wrap;
}

svg {
	vertical-align: -2px;
}