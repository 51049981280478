@import "src/css/global";

.container {
	width: 100%;
	
	display: flex;
	align-items: center;
	margin-bottom: 45px;
	
	label {
		
		.searchByText {
			display: block;
			appearance: none;
			width: 100%;
			
			border: solid 1px;
			border-color: black transparent;
		}
	}
	
	.submitBtn {
		// 초기화
		appearance: none;
		font-size: 1em;
		
		
		background: $sungyun-red;
		color: white;
		
		border: solid 1px $sungyun-red;
		
		border-radius: 5px;
		cursor: pointer;
		
		transition: opacity .3s ease;
		
		&:hover {
			opacity: .7;
		}
	}
	
	@media (min-width: 768px) {
		label {
			flex: 0 1 100%;
			
			.searchByText {
				font-size: 18px;
				padding: 15px 20px;
			}
		}
		
		.submitBtn {
			font-size: 18px;
			margin-left: 40px;
			padding: 15px 20px;
			flex: 0 1 120px;
			
		}
	}
	
	@media (max-width: 767px) and (min-width: 426px) {
		flex-direction: column;
		
		label {
			width: 100%;
			
			.searchByText {
				font-size: 18px;
				padding: 20px 15px;
			}
		}
		
		.submitBtn {
			width: 100%;
			font-size: 18px;
			padding: 20px 0;
			margin-top: 50px;
		}
	}
	
	@media (max-width: 425px) {
		flex-direction: column;
		
		label {
			width: 100%;
			
			.searchByText {
				font-size: 13px;
				padding: 15px 20px;
			}
		}
		
		.submitBtn {
			width: calc(100% - 50px);
			font-size: 13px;
			padding: 15px 0;
			margin: 20px 25px 0 25px;
		}
	}
}