@import "src/css/global";

//.articleContainer {
//	min-width: 1000px;
//	margin: 0 auto;
//}

#ProductInfo {
	.infoContainer {
		position: relative;
		display: flex;
		justify-content: space-between;
		
		.text {
			.infoTitle {
				font-weight: $bold;
			}
			
			.infoSummary {
				font-weight: $light;
			}
			
			.infoLink {
				font-weight: $bold;
				color: $sungyun-red;
			}
		}
		
		img {
			object-fit: cover;
		}
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		.articleContainer {
			padding: 80px 0 105px 0;
		}
		
		.infoContainer {
			.text {
				margin-right: 100px;
				
				.infoTitle {
					font-size: 34px;
					margin-bottom: 25px;
				}
				
				.infoSummary {
					font-size: 16px;
					margin-bottom: 55px;
				}
				
				.infoLink {
					font-size: 16px;
					margin-bottom: 100px;
				}
			}
			
			img {
				width: 210px;
				height: 210px;
			}
		}
		
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		.articleContainer {
			padding: 90px 0 100px 0;
		}
		
		.infoContainer {
			.text {
				margin-right: 17.578vw;
				
				.infoTitle {
					font-size: 5.429vw;
					margin-bottom: 5.208vw;
				}
				
				.infoSummary {
					font-size: 2.343vw;
					margin-bottom: 5.208vw;
				}
				
				.infoLink {
					font-size: 2.343vw;
					margin-bottom: 9.765vw;
				}
			}
			
			img {
				width: 26.041vw;
				height: 26.041vw;
			}
		}
		
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		.articleContainer {
			padding: 60px 0;
		}
		
		.infoContainer {
			.text {
				margin-right: 13.333vw;
				
				
				.infoTitle {
					font-size: 6.133vw;
					margin-bottom: 6.666vw;
				}
				
				.infoSummary {
					font-size: 3.466vw;
					margin-bottom: 8vw;
				}
				
				.infoLink {
					font-size: 3.466vw;
					margin-bottom: 13.333vw;
				}
			}
			
			img {
				width: 28vw;
				height: 28vw;
			}
		}
		
	}
}