@import "src/css/global";

.items {
	& > a {
		transition: color .3s ease;
		
		&:hover {
			color: $sungyun-red !important;
		}
	}
}

.headerItem:last-child a {
	font-weight: $bold;
}

// PC
@media (min-width: 1024px) {
	.items:not(:last-child) {
		margin-right: 45px;
	}
	
	a:not(:last-child) {
		margin-right: 45px;
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.link {
		height: 35px;
		margin-right: 20px;
	}
	
}

// Mobile
@media (max-width: 425px) {
	.link {
		height: 30px;
		margin-right: 20px;
	}
}