@import "src/css/global";

:global .ReactCollapse--collapse {
	transition: height .3s ease;
}

.header {
	position: fixed;
	top: 0;
	z-index: 167;
	background: none;
	width: 100%;
	
	.mobile.true ~ .headerSubMenu {
		background-color: $sungyun-indigo;
	}
	
	.headerSubMenu {
		transition: background-color .3s ease, color .3s ease;
	}
	
	.mobile {
		&.true {
			background-color: $sungyun-indigo;
		}
		
		transition: background-color .3s ease;
		background: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		.link {
			.logo {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		
		.headerMenu {
			display: flex;
			color: white;
			
			.headItem {
				display: flex;
				list-style: none;
				align-items: center;
				
				&:not(:last-child):after {
					display: inline;
					content: "";
					padding-left: 1px;
					height: 15px;
					background-color: white;
					vertical-align: 5px;
				}
				
				&:last-child > a {
					font-weight: $bold;
				}
				
			}
			
			a {
				color: white;
			}
			
			button {
				display: flex;
				align-items: center;
				color: inherit;
				transition: opacity .3s ease;
				border: none;
				background-color: transparent;
				
				svg {
					font-size: 20px;
				}
				
				&:hover {
					opacity: .7;
				}
			}
		}
	}
	
	.mobileMenu {
		background-color: white;
	}
}

@media screen and (min-width: 1024px) {
	.mobile {
		height: 64px;
		padding: 0 40px;
		
		.link {
			width: 115px;
			height: 28px;
		}
		
		.headItem {
			margin-left: 45px;
			
			&:not(:last-child):after {
				margin-left: 45px;
			}
		}
	}
}

@media (max-width: 1023px) and (min-width: 768px) {
	.mobile {
		height: 75px;
		padding: 0 45px;
		
		.link {
			height: 35px;
			margin-right: 20px;
		}
	}
}

@media (max-width: 767px) {
	.mobile {
		height: 64px;
		padding: 0 20px;
		
		.link {
			height: 30px;
			margin-right: 20px;
		}
	}
}