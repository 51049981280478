@import "src/css/global";

.container {
	
	.current {
		b {
			font-size: inherit;
			color: $sungyun-red;
		}
	}
	
	.noListWarn {
		padding: 150px 0;
		text-align: center;
	}
	
	.listWrap {
		.header {
			display: flex;
			border-top: solid 2px #1E1E23;
			border-bottom: solid 1px #7F7F7F;
			font-weight: $bold;
			background-color: #F7F7F7;
			
			.column {
				padding: 15px 25px;
				
				&:not(:last-child) {
					border-right: solid 1px #7F7F7F;
				}
			}
		}
	}
	
	.buttonWrap {
		display: flex;
		justify-content: center;
		
		.button {
			background: transparent;
			border: solid 1px #A8ACAF;
			border-radius: 5px;
			color: #A8ACAF;
			cursor: pointer;
			padding: 20px 25px;
		}
	}
}

// PC
@media (min-width: 1024px) {
	.container {
		padding: 65px 0 75px 0;
		
		.current {
			font-size: 12px;
		}
		
		.noListWarn {
			padding: 150px 0;
			
			.warnText {
				font-size: 30px;
			}
		}
		
		.listWrap {
			margin-top: 40px;
			margin-bottom: 55px;
			
			.header {
				.column {
					padding: 15px 25px;
					
					&:nth-child(1) {
						flex: 0 1 75px;
					}
					
					&:nth-child(2) {
						flex: 0 1 150px;
					}
					
					&:nth-child(3) {
						flex: 0 0 calc(100% - 350px);
					}
					
					&:nth-child(4) {
						flex: 0 1 125px;
					}
				}
			}
		}
		
		.buttonWrap {
			
			.button {
				font-size: 17.64px;
				padding: 20px 25px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.container {
		padding: 65px 0 75px 0;
		
		.current {
			font-size: 12px;
		}
		
		.noListWarn {
			padding: 150px 0;
			
			.warnText {
				font-size: 30px;
			}
		}
		
		.listWrap {
			margin-top: tablet(25px);
			margin-bottom: tablet(45px);
			
			.header {
				font-size: 18px;
				
				.column {
					padding: tablet(15px) tablet(25px);
					
					&:nth-child(1) {
						width: 10%;
					}
					
					&:nth-child(2) {
						display: none;
					}
					
					&:nth-child(3) {
						width: 70%;
					}
					
					&:nth-child(4) {
						width: 20%;
					}
				}
			}
		}
		
		.buttonWrap {
			justify-content: center;
			
			.button {
				font-size: 16px;
				padding: tablet(20px) tablet(240px);
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.container {
		padding: 65px 0 75px 0;
		
		.current {
			font-size: 12px;
		}
		
		.noListWarn {
			padding: 150px 0;
			
			.warnText {
				font-size: 30px;
			}
		}
		
		.listWrap {
			margin-top: tablet(25px);
			margin-bottom: tablet(45px);
			
			.header {
				font-size: 18px;
				
				.column {
					padding: tablet(30px) tablet(25px);
					border-right: none !important;
					
					&:nth-child(1) {
						display: none;
					}
					
					&:nth-child(2) {
						display: none;
					}
					
					&:nth-child(3) {
						width: 100%;
					}
					
					&:nth-child(4) {
						display: none;
					}
				}
			}
		}
		
		.buttonWrap {
			justify-content: center;
			
			.button {
				font-size: 16ㅔㅌ;
				padding: tablet(20px) tablet(240px);
			}
		}
	}
}