@import "src/css/global";

.products {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	
	.productItemImg {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	
	.text {
		text-align: center;
		
		.productItemNameEN {
			font-weight: $light;
		}
		
		.productItemNameKO {
			font-weight: $light;
		}
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		margin-right: 17px;
		
		.productItemImg {
			height: 170px;
			width: 170px;
		}
		
		.text {
			width: 170px;
			
			.productItemNameEN {
				font-size: 13px;
				margin-top: 30px;
			}
			
			.productItemNameKO {
				font-size: 21px;
				margin-top: 13px;
			}
		}
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		margin-right: tablet(19px);
		
		.productItemImg {
			width: tablet(210px);
			height: tablet(210px);
		}
		
		.text {
			width: tablet(210px);
			
			.productItemNameEN {
				font-size: 1.953vw;
				margin-top: 5.859vw;
			}
			
			.productItemNameKO {
				font-size: 3.906vw;
				margin-top: 2.604vw;
			}
		}
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		margin-right: mobile(10px);
		
		.productItemImg {
			width: mobile(101px);
			height: mobile(101px);
		}
		
		.text {
			width: mobile(101px);
			
			.productItemNameEN {
				font-size: 2.4vw;
				margin-top: 3.6vw;
			}
			
			.productItemNameKO {
				font-size: mobile(13px);
			}
		}
	}
}