@import "src/css/global";

:global #ResetPW {
	display: flex;
	justify-content: center;
	align-items: center;
	
	background-color: $sungyun-indigo;
	color: white;
}

.container {
	border: solid 1px #95999D;
	border-radius: 10px;
	
	p.text {
		font-weight: $light;
		line-height: 150%;
	}
	
	.form {
		.label {
			display: block;
			font-weight: $light;
			
			b {
				color: $sungyun-red;
				font-weight: $bold;
			}
		}
		
		.input {
			padding: 25px 15px;
			width: 100%;
			
			background-color: white;
			border: none;
			
			font-weight: $light;
			
			color: black;
		}
		
		.button {
			display: block;
			width: 100%;
			
			outline: none;
			
			background-color: $sungyun-red;
			border: none;
			color: white;
			
			font-weight: $bold;
		}
	}
	
	div.text {
		display: flex;
		justify-content: space-between;
		
		a {
			font-weight: $light;
		}
	}
}

// PC
@media (min-width: 1024px) {
	:global #ResetPW {
		padding: 190px 0 170px 0;
	}
	
	.container {
		width: 510px;
		padding: 40px;
		
		p.text {
			font-size: 14px;
			margin-bottom: 25px;
		}
		
		.form {
			margin-bottom: 25px;
			padding: 0;
			
			.label {
				font-size: 14px;
				margin-bottom: 15px;
			}
			
			.input {
				padding: 25px 15px;
				font-size: 16px;
				margin-bottom: 25px;
			}
			
			.button {
				padding: 20px 0;
			}
		}
		
		div.text {
			a {
				font-size: 13px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	:global #ResetPW {
		padding: 270px 40px 260px 40px;
	}
	
	.container {
		width: 510px;
		padding: 45px;
		
		p.text {
			font-size: 16px;
			margin-bottom: 45px;
		}
		
		.form {
			margin-bottom: 25px;
			padding: 0;
			
			.label {
				font-size: 16px;
				margin-bottom: 25px;
			}
			
			.input {
				padding: 15px 0;
				font-size: 18px;
				margin-bottom: 25px;
			}
			
			.button {
				padding: 20px 0;
			}
		}
		
		div.text {
			a {
				font-size: 13px;
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	:global #ResetPW {
		padding: 200px 20px;
	}
	
	.container {
		width: 335px;
		padding: 20px 30px;
		
		p.text {
			font-size: 9px;
			margin-bottom: 17.5px;
		}
		
		.form {
			margin-bottom: 10px;
			padding: 0;
			
			.label {
				font-size: 10px;
				margin-bottom: 10px;
			}
			
			.input {
				padding: 15px 12.5px;
				font-size: 13px;
				margin-bottom: 15px;
			}
			
			.button {
				font-size: 13px;
				padding: 10px 0;
			}
		}
		
		div.text {
			a {
				font-size: 9px;
			}
		}
	}
}