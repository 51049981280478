@import "src/css/global";

.CONTAINER {
	padding: 65px 0 75px 0;
}

.userInfoContainer {
	border-top: solid 2px #1E1E23;
	
	.infoColumn {
		display: flex;
		flex: 1 1 50%;
		
		&:nth-child(2n) {
			border-left: solid 1px #93929B;
		}
		
		div {
			border-bottom: solid 1px #93929B;
		}
		
		.key {
			border-right: solid 1px $sungyun-indigo;
			font-weight: $bold;
			width: 120px;
		}
	}
}

.articleInfoContainer {
	border-top: solid 2px #1E1E23;
	
	.header {
		display: flex;
		background-color: #F7F7F7;
		font-weight: $bold;
	}
	
	.article {
		display: flex;
	}
	
	.header div, .article div {
		border-bottom: solid 1px #93929B;
		
		&:not(:last-child) {
			border-right: solid 1px #93929B;
		}
	}
	
	.article div {
		font-weight: $light;
		
		.isAnswered {
			background-color: $sungyun-indigo;
			color: white;
			padding: 2.5px 25px;
			border-radius: 500px;
			margin-left: 15px;
			
			&.false {
				background-color: $sungyun-red;
			}
		}
		
		a {
			text-decoration: underline;
		}
	}
}

.titleContainer {
	border-top: solid 2px #1E1E23;
	
	div {
		padding: 15px 25px;
		border-bottom: solid 1px #93929B;
	}
	
	.key {
		width: 100%;
		font-weight: $bold;
		background-color: #F7F7F7;
	}
	
	.value {
		width: 100%;
		font-weight: $light;
		
		.isAnswered {
			display: inline-block;
			background-color: $sungyun-indigo;
			color: white;
			padding: 2.5px 25px;
			border-radius: 500px;
			margin-left: 15px;
			
			&.false {
				background-color: $sungyun-red;
			}
		}
	}
}

.asideContainer {
	border-top: solid 2px #1E1E23;
	
	.file, .answer {
		display: flex;
		
		.key {
			border-right: solid 1px #93929B;
		}
		
		.key, .value {
			border-bottom: solid 1px #93929B;
		}
		
		.key {
			font-weight: $bold;
			width: 120px;
		}
		
		.value {
			font-weight: $light;
			width: calc(100% - 120px);
		}
	}
	
	.answer {
		background-color: #F7F7F7;
	}
}

.content {
	font-weight: $light;
	border-bottom: solid 1px #93929B;
}

.linkContainer {
	display: flex;
	justify-content: center;
	
	.link {
		background: transparent;
		border: solid 1px #A8ACAF;
		border-radius: 5px;
		color: #A8ACAF;
		cursor: pointer;
	}
}

// PC
@media (min-width: 1024px) {
	.userInfoContainer {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		
		.infoColumn {
			div {
				padding: 15px 25px;
			}
			
			.key {
				width: 120px;
			}
			
			.value {
				width: calc(100% - 120px);
			}
		}
	}
	
	.articleInfoContainer {
		border-top: solid 2px #1E1E23;
		margin-bottom: 20px;
		
		.header div, .article div {
			padding: 15px 25px;
			
			&:nth-child(1) {
				width: 150px;
			}
			
			&:nth-child(2) {
				width: 250px;
			}
			
			&:nth-child(3) {
				width: 100%;
			}
			
			&:nth-child(4) {
				width: 270px;
			}
		}
	}
	
	.asideContainer {
		.file, .answer {
			div {
				padding: 15px 25px;
			}
		}
	}
	
	.content {
		padding: 50px;
		margin-bottom: 50px;
	}
	
	.linkContainer {
		a, .link {
			padding: 15px 25px;
			font-size: 18px;
			
			&:not(:first-child) {
				margin-left: 35px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.userInfoContainer {
		margin-bottom: tablet(20px);
		flex-direction: column;
		
		.infoColumn {
			display: flex;
			border-left: none !important;
			
			div {
				display: flex;
				align-items: center;
				padding: tablet(15px) tablet(25px);
			}
			
			.key {
				width: 25%;
				border-right: none;
			}
			
			.value {
				width: 75%;
			}
		}
	}
	
	.articleInfoContainer {
		border-top: solid 2px #1E1E23;
		margin-bottom: tablet(20px);
		
		.header div, .article div {
			padding: tablet(15px) tablet(25px);
			
			&:nth-child(1) {
				width: 15%;
			}
			
			&:nth-child(2) {
				width: 60%;
			}
			
			&:nth-child(3) {
				width: 27.5%;
			}
		}
	}
	
	.titleContainer {
		margin-bottom: tablet(25px);
	}
	
	.asideContainer {
		.answer, .file {
			flex-wrap: wrap;
			
			.key {
				padding: tablet(15px) tablet(25px) !important;
				width: 100%;
				border-right: none;
				background-color: white;
			}
			
			.value {
				width: 100%;
				padding: tablet(15px) tablet(25px);
			}
		}
		
		.answer:not(:first-child) {
			border-top: solid 2px #1E1E23;
			margin-top: tablet(30px);
		}
		
		.file {
			.key {
				background-color: #F7F7F7;
			}
		}
	}
	
	.content {
		padding: tablet(65px) tablet(50px);
		margin-bottom: tablet(25px);
	}
	
	.linkContainer {
		margin-top: tablet(65px);
		
		.link {
			padding: tablet(25px);
			font-size: tablet(17.64px);
			margin-right: tablet(35px);
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	font-size: 13px;
	
	.userInfoContainer {
		// ??? 이 코드가 있으면 왜 CSS가 적용되는거지
		margin-bottom: mobile(25px);
		
		.infoColumn {
			display: flex;
			border-left: none !important;
			
			div {
				display: flex;
				align-items: center;
				padding: mobile(15px) mobile(12.5px);
			}
			
			.key {
				width: mobile(135px);
				border-right: none;
			}
			
			.value {
				width: 100%;
			}
		}
	}
	
	.articleInfoContainer {
		margin: mobile(25px) 0;
		border-top: solid 2px #1E1E23;
		
		.header div, .article div {
			padding: mobile(15px) mobile(12.5px);
			
			&:nth-child(1) {
				width: mobile(75px);
			}
			
			&:nth-child(2) {
				width: 100%;
			}
			
			&:nth-child(3) {
				width: mobile(130px);
			}
		}
	}
	
	.titleContainer {
		margin-bottom: mobile(25px);
	}
	
	.asideContainer {
		.answer, .file {
			flex-wrap: wrap;
			
			.key {
				padding: mobile(15px) mobile(12.5px) !important;
				width: 100%;
				border-right: none;
				background-color: white;
			}
			
			.value {
				width: 100%;
				padding: mobile(15px) mobile(12.5px);
			}
		}
		
		.file {
			.key {
				background-color: #F7F7F7;
			}
		}
	}
	
	.content {
		padding: mobile(65px) mobile(50px);
		margin-bottom: mobile(25px);
	}
	
	.linkContainer {
		margin-top: mobile(5px);
		
		.link {
			padding: mobile(15px) mobile(10px);
			font-size: mobile(13px);
			margin-right: 12px;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}