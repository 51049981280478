@import "src/css/global";

.container {
	padding: 75px 0;
}

.titleContainer {
	display: flex;
	border-top: solid 2px #1E1E23;
	
	div {
		padding: 15px 25px;
		border-bottom: solid 1px #93929B;
	}
	
	.key {
		width: 25%;
		font-weight: $bold;
	}
	
	.value {
		width: 75%;
		font-weight: $light;
		
		.isAnswered {
			display: inline-block;
			background-color: $sungyun-indigo;
			color: white;
			padding: 2.5px 25px;
			border-radius: 500px;
			margin-left: 15px;
			
			&.false {
				background-color: $sungyun-red;
			}
		}
	}
}

.asideContainer {
	.file {
		border-top: solid 2px #1E1E23;
		display: flex;
		
		.key {
			border-right: solid 1px #93929B;
		}
		
		.key, .value {
			border-bottom: solid 1px #93929B;
		}
		
		.key {
			font-weight: $bold;
			width: 100px;
		}
		
		.value {
			font-weight: $light;
			width: calc(100% - 100px);
		}
	}
	
	.answer {
		background-color: #F7F7F7;
	}
}

.form {
	padding: 0;
}

.content {
	font-weight: $light;
	border-bottom: solid 1px #93929B;
	padding: 50px;
	margin-bottom: 50px;
}

.textarea {
	margin-bottom: 20px;
	display: block;
	width: 100%;
	min-height: 25pc;
	resize: vertical;
}

.btnContainer {
	display: flex;
	justify-content: center;
	
	.submitBtn {
		background: transparent;
		border: solid 1px #A8ACAF;
		border-radius: 5px;
		color: #A8ACAF;
		cursor: pointer;
		padding: 20px 25px;
	}
}