@import "src/css/global";

.InfoSummary {
	$border-color : #4A5057;
	background-color: $sungyun-indigo;
	border-bottom: solid 1px $sungyun-lightgray;
	
	.container {
		display: flex;
		padding: 0 53px;
		margin: 0 auto;
		
		.summary {
			flex: 1 1 50%;
			color: white;
			
			h2 {
				font-weight: $bold;
			}
			
			p {
				font-weight: $light;
				line-height: 135%;
			}
			
			a {
				color: $sungyun-red;
				font-weight: $bold;
			}
			
			img {
				width: 100%;
			}
		}
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		.summary {
			padding: 65px 53px 80px 53px;
			
			h2 {
				font-size: 34px;
				margin-bottom: 30px;
			}
			
			p {
				font-size: 16px;
				margin-bottom: 80px;
			}
			
			a {
				margin-bottom: 125px;
			}
			
			&:first-child {
				padding-left: 0;
				border-right: solid 1px $border-color;
			}
			
			&:last-child {
				padding-right: 0;
			}
		}
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		.container {
			flex-direction: column;
			
			.summary {
				padding: 90px 0;
				
				h2 {
					font-size: 4.427vw;
					margin-bottom: 5.859vw;
				}
				
				p {
					font-size: mobile(15px);
					margin-bottom: 9.114vw;
				}
				
				a {
					font-size: 2.083vw;
					margin-bottom: 17.578vw;
				}
				
				&:first-child {
					border-bottom: solid 1px $border-color;
				}
			}
		}
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		.container {
			flex-direction: column;
			
			.summary {
				padding: 55px 0;
				
				h2 {
					font-size: 6.133vw;
					margin-bottom: 6.666vw;
				}
				
				p {
					font-size: 3.466vw;
					margin-bottom: 10.666vw;
				}
				
				a {
					margin-bottom: 10.666vw;
				}
				
				&:first-child {
					border-bottom: solid 1px $border-color;
				}
			}
		}
	}
}