@import "src/css/global";

:global #ProductSearchResult > aside > h1 { font-size: 40px }

:global #ProductSearchResult #ProductList { padding-top: 0; }

.productHelp {
	padding-top: 80px;
}

.result {
	border-top: solid 1px $sungyun-lightgray;
	
	.counter {
		.bold {
			color: $sungyun-red;
		}
	}
	
	// PC
	@media (min-width: 768px) {
		padding: 67px 0 135px 0;
		
		.counter {
			margin-bottom: 25px;
		}
	}
	
	// Tablet
	@media (max-width: 767px) and (min-width: 426px) {
		padding: 85px 0;
		
		.counter {
			margin-bottom: 20px;
		}
	}
	
	// Mobile
	@media (max-width: 425px) {
		padding: 65px 0;
		
		.counter {
			margin-bottom: 25px;
		}
	}
}