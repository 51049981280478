@import "src/css/global";

.mobileMenu {
	background-color: white;
	width: 100%;
	overflow: scroll;
	
	@media screen and (max-width: 1023px) and (min-width: 768px) {
		height: calc(100vh - 75px);
	}
	
	
	@media screen and (max-width: 767px) {
		height: calc(100vh - 63px);
	}
}