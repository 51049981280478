@import "src/css/global";

#ProductInfoResult {
	border-top: solid 1px $sungyun-gray;
	
	.selected {
		background: rgb(255, 193, 1);
		
		.header {
			background: inherit;
		}
	}
	
	.column {
		position: relative;
		
		img {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	
	.tableWrapper {
		width: 100%;
		overflow-x: auto !important;
		
		.resTable {
			
			.tableName {
				position: sticky;
				margin-bottom: 30px;
				width: 100%;
			}
			
			.column {
				span {
					padding: 15px;
					width: 120px;
				}
				
				.container {
					width: 100%;
				}
			}
		}
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		padding: 67px 0 85px 0;
		
		.tableWrapper {
			margin-top: 65px;
		}
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		padding: 85px 0 75px 0;
		
		.resTable {
			width: $content-max;
		}
		
		.tableWrapper {
			margin-top: 75px;
		}
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		padding: 65px 0;
		
		.resTable {
			width: $content-max;
		}
		
		.tableWrapper {
			margin-top: 35px;
		}
	}
}