@import "src/css/global";

.container {
	display: flex;
	
	.images {
		height: 100% !important;
	}
}

.text {
	.title {
		font-family: "Poppins", sans-serif;
		font-weight: $bold;
		line-height: 90%;
	}
	
	.subTitle, .text {
		font-weight: $light;
	}
	
	.subTitle {
		line-height: 163%;
		
		&:first-child {
			margin-bottom: 0;
		}
	}
	
	.text {
		margin-top: 0;
		line-height: 200%;
		margin-right: 0;
	}
	
	.link {
		display: inline-block;
		padding: 15px 35px;
		border-radius: 500px;
		border: solid 1px $sungyun-red;
		color: $sungyun-red;
		transition: color .2s ease, background-color .2s ease;
		
		&:hover {
			color: white;
			background-color: $sungyun-red;
		}
	}
}

// PC
@media (min-width: 1024px) {
	:global #Career article > div {
		max-width: none;
	}
	
	.container {
		padding: 150px 0;
		
		.text {
			padding-top: 75px;
			min-width: 60%;
			margin-right: 25px;
			
			.title {
				font-size: 50px;
				margin-bottom: 30px;
			}
			
			.subTitle {
				display: block;
				font-size: 21px;
				margin-bottom: 30px;
			}
			
			.text {
				font-size: 15px;
				margin-bottom: 75px;
			}
		}
		
		.images {
			max-width: 40% !important;
			flex: 0 1 40%;
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.container {
		padding: 85px 50px;
		flex-direction: column-reverse;
	}
	
	.text {
		margin-top: tablet(65px);
		
		.title {
			font-size: tablet(45px);
			margin-bottom: tablet(30px);
		}
		
		.subTitle {
			font-size: tablet(21px);
			margin-bottom: tablet(15px);
		}
		
		.text {
			font-size: tablet(16px);
			margin-bottom: tablet(65px);
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.container {
		padding: 45px 25px;
		flex-direction: column-reverse;
	}
	
	.text {
		margin-top: mobile(65px);
		
		.title {
			font-size: mobile(23px);
			margin-bottom: mobile(15px);
		}
		
		.subTitle {
			font-size: mobile(21px);
			margin-bottom: mobile(15px);
		}
		
		.text {
			font-size: mobile(12px);
			margin-bottom: mobile(30px);
		}
	}
}