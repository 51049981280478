@import "src/css/global";
@import "../TableRow";

$border-color: $sungyun-gray;


.column {
	// PC
	//@media screen and (min-width: 1024px) {
		display: flex;
		border-bottom: solid 1px $border-color;
		
		span {
			display: flex;
			align-items: center;
			font-weight: $light;
			border-right: solid 1px $border-color;
		}
		
		&.last {
			span {
				width: 100% !important;
				border-right: none;
			}
			
			.container {
				display: none !important;
			}
		}
		
		.container {
			display: flex;
			flex-direction: column;
			
			.column {
				height: 100%;
				
				&:last-child {
					border-bottom: none;
				}
				
				.container {
					
					span {
						width: 100%;
					}
					
					&:last-child {
						border-right: none;
					}
				}
			}
		}
	//}
	
	//@media screen and (max-width: 1023px) {
	//	width: 100%;
	//
	//	span {
	//		border-top: solid 2px black;
	//		border-bottom: solid 1px $sungyun-gray;
	//		background-color: $tableBgColor;
	//		font-weight: $bold;
	//
	//		.text {
	//			font-style: normal;
	//		}
	//	}
	//
	//	.container {
	//		width: 100% !important;
	//
	//		span {
	//			border-top: none;
	//			background-color: transparent;
	//			font-weight: $light;
	//		}
	//
	//		.container {
	//			width: 100%;
	//
	//			span {
	//				background-color: $tableBgColor;
	//			}
	//		}
	//	}
	//}
}