@import "src/css/global";

:global #InquiryLogin article {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
}

.container {
	
	.title {
		display: block;
		font-family: "GreycliffCF", sans-serif;
		text-align: center;
	}
	
	.form {
		margin: 0 auto;
		
		.input {
			display: block;
			padding: 20px 0;
			width: 100%;
			
			background-color: transparent;
			border: solid 1px;
			
			font-weight: $light;
			
			color: black;
			border-color: #808080 transparent;
			
			&:first-child {
				margin-bottom: 0;
				border-bottom-color: transparent;
			}
		}
		
		.button {
			display: block;
			width: 100%;
			
			background-color: $sungyun-red;
			color: white;
			border: none;
			cursor: pointer;
			outline: none;
			
			transition: background-color .3s ease;
			
			&:hover {
				background-color: rgba($sungyun-red, .7);
			}
		}
	}
	
	.text {
		display: flex;
		justify-content: space-between;
		
		.link {
			font-weight: $light;
		}
	}
}

// PC
@media screen and (min-width: 1024px) {
	.container {
		padding: 150px 0 110px 0;
		
		.title {
			font-size: 45px;
			margin-bottom: 50px;
		}
		
		.form {
			width: 430px;
			margin-bottom: 25px;
			
			.input {
				font-size: 18px;
				margin-bottom: 25px;
			}
			
			.button {
				padding: 25px 0;
				font-size: 18px;
			}
		}
		
		.link {
			font-size: 13px;
		}
	}
}

// Tablet
@media screen and (max-width: 1023px) and (min-width: 426px) {
	.container {
		padding: 256px 0 175px 0;
		
		.title {
			font-size: tablet(45px);
			margin-bottom: tablet(50px);
		}
		
		.form {
			width: tablet(570px);
			margin-bottom: tablet(25px);
			
			.input {
				font-size: tablet(18px);
				margin-bottom: tablet(25px);
			}
			
			.button {
				padding: tablet(25px) 0;
				font-size: tablet(18px);
			}
		}
		
		.link {
			font-size: tablet(13px);
		}
	}
}

// Mobile
@media screen and (max-width: 425px) {
	.container {
		padding: 100px 0 150px 0;
		
		.title {
			font-size: mobile(20px);
			margin-bottom: mobile(25px);
		}
		
		.form {
			width: mobile(280px);
			margin-bottom: mobile(12.5px);
			
			.input {
				font-size: mobile(13px);
				margin-bottom: mobile(12.5px);
			}
			
			.button {
				padding: mobile(15px) 0;
				font-size: mobile(13px);
			}
		}
		
		.link {
			font-size: mobile(10px);
		}
	}
}