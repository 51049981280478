@import "src/css/global";

.MainSecIndex {
	position: relative;
	display: flex;
	align-items: center;
	color: white;
	height: 100vh;
	background-color: rgba(0,0,0,0.3);
	max-width: 100vw;
	overflow: hidden;
	
	.video {
		position : absolute;
		top : 50%;
		left : 50%;
		min-width : 100%;
		min-height : 100%;
		width : auto;
		height : auto;
		z-index : -1;
		transform: translate(-50%, -50%) scale(1.5);
	}
	
	.container {
		max-width: none !important;
		margin: 0;
		
		.title {
			font-family: "Poppins", sans-serif;
			line-height: 100%;
		}
		
		.summary {
			line-height: 150%;
			font-weight: $light;
		}
		
		.link {
			display: inline-block;
			padding: 15px 35px;
			border-radius: 500px;
			border: solid 2px $sungyun-red;
			color: $sungyun-red;
			transition: color .2s ease, background-color .2s ease;
			
			&:hover {
				color: white;
				background-color: $sungyun-red;
			}
		}
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		.container {
			padding: 0 53px;
		}
		
		.title {
			font-size: 75px;
			margin-bottom: 45px;
		}
		
		.summary {
			font-size: 19px;
			margin-bottom: 75px;
		}
		
		.link {
			font-size: 19px;
		}
		
		.scroll {
			position: absolute;
			bottom: 50px;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			
			.scrollBody {
				position: relative;
				width: 30px;
				height: 50px;
				border: 2px solid #fff;
				border-radius: 50px;
				box-sizing: border-box;
				
				&:before {
					position: absolute;
					top: 10px;
					left: 50%;
					content: '';
					width: 6px;
					height: 6px;
					margin-left: -3px;
					background-color: #fff;
					border-radius: 100%;
					animation: sdb 2s infinite;
					box-sizing: border-box;
				}
				
				@keyframes sdb {
					0% {
						transform: translate(0, 0);
						opacity: 0;
					}
					40% {
						opacity: 1;
					}
					80% {
						transform: translate(0, 20px);
						opacity: 0;
					}
					100% {
						opacity: 0;
					}
				}
			}
		}
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		.container {
			padding: 0 40px;
		}
		
		.title {
			font-size: tablet(80px);
			margin-bottom: 5.85vw;
		}
		
		.summary {
			font-size: tablet(24px);
			margin-bottom: 8.46vw;
		}
		
		.link {
			font-size: tablet(24px);
		}
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		.container {
			padding: 0 20px;
		}
		
		.title {
			font-size: 10.133vw;
			margin-bottom: 8vw;
		}
		
		.summary {
			font-size: 3.466vw;
			margin-bottom: 8vw;
		}
		
		.link {
			font-size: 3.466vw;
		}
	}
}