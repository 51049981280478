@import "src/css/global";

.text {
	font-weight: $light;
	
	b {
		font-size: inherit;
	}
}

article {
	.subTitle {
		font-weight: $light;
		
		b {
			font-size: inherit;
			color: $sungyun-red;
			
		}
		
		svg {
			font-size: inherit;
			vertical-align: -3px;
		}
	}
	
	&.logo {
		.logoWrap {
			border: solid 1px #95999D;
			
			.Dark {
				background-color: $sungyun-indigo;
			}
		}
		
		.linkWrap {
			display: flex;
			justify-content: center;
			
			.downloadLink {
				background-color: $sungyun-indigo;
				color: white;
			}
		}
	}
	
	&.regulation {
		.container {
			border: solid 1px #95999D;
		}
	}
	
	&.paletteContainer {
		&:last-child {
			margin-bottom: 0;
		}
		
		.paletteWrap {
			display: flex;
			flex-wrap: wrap;
			
			.palette {
				.color {
					width: 100%;
				}
				
				.text {
					padding: 0;
					margin-bottom: 0;
					font-weight: 200;
					font-size: 15px;
				}
			}
		}
	}
}

// PC
@media (min-width: 1024px) {
	.container {
		padding-top: 65px;
		padding-bottom: 80px;
		
		* {
			font-size: 15px;
		}
		
		.text {
			display: flex;
			justify-content: space-between;
			margin-bottom: 115px;
			
			.title {
				flex: 1 1 50%;
				margin-right: 80px;
				font-size: 25px;
				line-height: 36px;
			}
			
			.summary {
				flex: 1 1 50%;
				font-size: 15px;
				line-height: 28px;
			}
		}
		
		article {
			margin-bottom: 80px;
			
			.subTitle {
				margin-bottom: 30px;
			}
			
			&.logo {
				.logoWrap {
					display: flex;
					margin-bottom: 80px;
					
					div {
						padding: 40px 60px;
						flex: 1 1 50%;
					}
					
					.Bright {
						flex: 1 1 50%;
					}
					
					.Dark {
						flex: 1 1 50%;;
					}
				}
				
				.linkWrap {
					.downloadLink {
						padding: 20px 90px;
						
						&:first-child {
							margin-right: 40px;
						}
					}
				}
			}
			
			&.regulation {
				.container {
					padding: 30px 80px;
				}
			}
			
			&.paletteContainer {
				.paletteWrap {
					.palette {
						flex: 1 1 33%;
						margin-bottom: 40px;
						
						.color {
							height: 60px;
							margin-bottom: 35px;
						}
						
						.text {
							display: block;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.container {
		padding-top: 85px;
		padding-bottom: 60px;
		
		.text {
			margin-bottom: 9.114vw;
			padding: 0 8.138vw;
			
			.title {
				font-size: 3.906vw;
				margin-bottom: 9.114vw;
			}
			
			.summary {
				font-size: 15px;
				line-height: 28px;
			}
		}
		
		article {
			margin-bottom: 7.8125vw;
			
			.subTitle {
				font-size: 2.343vw;
				margin-bottom: 5.859vw;
				padding: 0 8.138vw;
			}
			
			&.logo {
				.logoWrap {
					margin-bottom: 7.812vw;
					
					div {
						padding: 5.208vw 18.229vw;
					}
				}
				
				.linkWrap {
					.downloadLink {
						padding: 3.255vw 9.765vw;
						
						&:first-child {
							margin-right: 3.255vw;
						}
					}
				}
			}
			
			&.regulation {
				.container {
					padding: 6.510vw 5.859vw;
				}
			}
			
			&.paletteContainer {
				
				.paletteWrap {
					
					.palette {
						flex: 1 1 50%;
						margin-bottom: 5.208vw;
						
						.color {
							height: 7.812vw;
							margin-bottom: 4.557vw;
						}
					}
				}
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.container {
		padding-top: 45px;
		
		.text {
			margin-bottom: 17.333vw;
			padding: 0 8vw;
			
			.title {
				font-size: 4.266vw;
				margin-bottom: 9.333vw;
			}
			
			.summary {
				font-size: 3.466vw;
				line-height: 1.5;
			}
		}
		
		article {
			margin-bottom: 16vw;
			
			.subTitle {
				font-size: 3.466vw;
				margin-bottom: 6.666vw;
				padding: 0 8vw;
			}
			
			&.logo {
				.logoWrap {
					margin-bottom: 16vw;
					
					div {
						padding: 5.333vw 17.333vw;
					}
				}
				
				.linkWrap {
					flex-direction: column;
					align-items: center;
					
					.downloadLink {
						padding: 4vw 15vw;
						width: 75%;
						text-align: center;
						
						&:first-child {
							margin-bottom: 3.2vw;
						}
					}
				}
			}
			
			&.regulation {
				.container {
					padding: 6.666vw;
				}
			}
			
			&.paletteContainer {
				
				.paletteWrap {
					
					.palette {
						flex: 1 1 50%;
						margin-bottom: 10.666vw;
						
						.color {
							height: 16vw;
							margin-bottom: 9.333vw;
						}
					}
				}
			}
		}
	}
}