@import "src/css/global";

.footer {
	background-color: $sungyun-indigo;
	color: white;
	
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	
	.container {
		.list {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			
			li {
				display: flex;
				align-items: center;
				
				a {
					font-weight: $bold;
				}
				
				&:first-child {
					margin-left: 0;
				}
				
				&:not(:last-child):after {
					display: inline-block;
					content: "";
					padding-left: 1px;
					height: 15px;
					background-color: white;
				}
			}
		}
		
		.links {
			list-style: none;
			color: #89929b;
		}
		
		.address, .ccl {
			color: #89929b;
		}
	}
	
	.images {
		height: 100%;
		width: 100%;
	}
}

// PC
@media (min-width: 1024px) {
	.footer {
		padding: 60px 55px 95px 55px;
		
		.list {
			margin-bottom: 60px;
			
			li {
				margin-left: 15px;
				
				a {
					font-size: 18px;
				}
				
				&:not(:last-child):after {
					margin-left: 15px;
				}
			}
		}
		
		.address {
			margin-bottom: 10px;
		}
		
		.links {
			margin-bottom: 15px;
		}
		
		.address, .ccl {
			font-size: 13px;
		}
		
		.images {
			width: 200px;
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.footer {
		display: block;
		padding: 50px 45px 95px 45px;
		
		.list {
			margin-bottom: 30px;
			
			li {
				margin-left: 16px;
				
				a {
					font-size: 18px;
				}
				
				&:not(:last-child):after {
					margin-left: 15px;
				}
			}
		}
		
		.address {
			margin-bottom: 20px;
		}
		
		.address {
			margin-bottom: 10px;
		}
		
		.links {
			margin-bottom: 15px;
		}
		
		.address, .ccl {
			font-size: 13px;
		}
		
		.images {
			margin-top: 30px;
			width: 25vw;
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.footer {
		display: block;
		padding: 25px 50px 45px 50px;
		
		.list {
			margin-bottom: 10px;
			
			li {
				margin-left: 16px;
				
				a {
					font-size: mobile(9px);
					margin-right: 0;
				}
				
				&:not(:last-child):after {
					height: 5px !important;
					margin-left: 16px;
				}
			}
		}
		
		.address {
			margin-bottom: 5px;
		}
		
		.links {
			margin-bottom: 10px;
		}
		
		.address, .ccl {
			font-size: 9px;
		}
		
		.images {
			margin-top: 30px;
			width: 25vw;
		}
	}
}