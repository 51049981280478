@import "src/css/global";

.background {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	padding: 0 20px;
	color: white;
	
	.link {
		margin-bottom: 20px;
		transition: color .3s ease;
		
		&:hover {
			color: $sungyun-red;
		}
	}
}