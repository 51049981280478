@import "src/css/global";

$tableBgColor: #F7F7F7;

.row {
	&.header {
		border-top: solid 2px black;
		background-color: $tableBgColor;
		
		span {
			font-weight: $bold;
		}
	}
	
	//// Tablet
	//@media screen and (max-width: 1023px) and (min-width: 426px) {
	//	margin-bottom: 40px;
	//}
	//
	//// Mobile
	//@media screen and (max-width: 425px) {
	//	margin-bottom: 15px;
	//}
}