@import "src/css/global";

.MainProduct {
	text-align: center;
	
	.container {
		max-width: $content-max;
		margin: 0 auto;
		padding: 60px 30px;
	}
	
	p {
		font-weight: $light;
	}
	
	.linkProduct {
		display: inline-block;
		border: none;
		color: white;
		background-color: $sungyun-indigo;
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		h2 {
			font-size: 33px;
			margin-bottom: 25px;
		}
		
		p {
			font-size: 16px;
			margin-bottom: 80px;
		}
		
		.linkProduct {
			margin-top: 80px;
			padding: 20px 80px;
			font-size: 15px;
		}
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 426px) {
		h2 {
			font-size: 5.468vw;
			margin-bottom: 5.859vw;
		}
		
		p {
			font-size: 2.343vw;
			margin-bottom: 17.578vw;
		}
		
		.linkProduct {
			margin-top: 8.463vw;
			padding: 2.604vw 28.645vw;
			font-size: 2.34vw;
			width: 100%;
			
			&:last-child {
				margin-top: 15px;
			}
		}
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		h2 {
			font-size: 6.133vw;
			margin-bottom: 6.666vw;
		}
		
		p {
			font-size: 3.466vw;
			margin-bottom: 29.333vw;
		}
		
		.linkProduct {
			width: 100%;
			margin-top: 29.333vw;
			padding: 4vw 26.666vw;
			font-size: 3.466vw;
			
			&:last-child {
				margin-top: 15px;
			}
		}
	}
}