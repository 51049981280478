@import "src/css/global";

:global #Login {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background: rgba(0, 0, 0, 0.3);
}

.bgImg {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: -1;
}

.container {
	.title {
		font-family: "GreycliffCF", sans-serif;
		text-align: center;
	}
	
	.form {
		.input {
			padding: 25px 15px;
			width: 100%;
			
			background-color: white;
			border: none;
			
			font-weight: $light;
			
			color: black;
		}
		
		.button {
			display: block;
			width: 100%;
			
			background-color: $sungyun-red;
			color: white;
			border: none;
			cursor: pointer;
			outline: none;
			font-weight: $bold;
			
			transition: background-color .3s ease;
			
			&:hover {
				background-color: rgba($sungyun-red, .7);
			}
		}
	}
	
	.text {
		display: flex;
		justify-content: space-between;
		
		.link {
			font-weight: $light;
		}
	}
}

// PC
@media screen and (min-width: 1024px) {
	.container {
		padding: 150px 0 110px 0;
		
		.title {
			font-size: 72px;
			margin-bottom: 170px;
		}
		
		.form {
			width: 430px;
			margin-bottom: 25px;
			
			.input {
				font-size: 18px;
				margin-bottom: 15px;
			}
			
			.button {
				margin-top: 10px;
				padding: 25px 0;
				font-size: 18px;
			}
		}
		
		.link {
			font-size: 13px;
		}
	}
}

// Tablet
@media screen and (max-width: 1023px) and (min-width: 426px) {
	.container {
		padding: 256px 0 175px 0;
		
		.title {
			font-size: tablet(72px);
			margin-bottom: tablet(150px);
		}
		
		.form {
			width: tablet(570px);
			margin-bottom: tablet(25px);
			
			.input {
				font-size: tablet(18px);
				margin-bottom: tablet(25px);
			}
			
			.button {
				padding: tablet(25px) 0;
				font-size: tablet(18px);
			}
		}
		
		.link {
			font-size: tablet(13px);
		}
	}
}

// Mobile
@media screen and (max-width: 425px) {
	.container {
		padding: 100px 0 150px 0;
		
		.title {
			font-size: mobile(38px);
			margin-bottom: mobile(150px);
		}
		
		.form {
			width: mobile(280px);
			margin-bottom: mobile(12.5px);
			
			.input {
				font-size: mobile(13px);
				margin-bottom: mobile(12.5px);
			}
			
			.button {
				padding: mobile(15px) 0;
				font-size: mobile(13px);
			}
		}
		
		.link {
			font-size: mobile(13px);
		}
	}
}