@import "src/css/global";

#Direction {
	.container {
		#map {
			width: 100%;
		}
		
		.LinkWrap {
			display: flex;
			justify-content: center;
			align-items: center;
			
			a {
				display: block;
				background-color: $sungyun-indigo;
				color: white;
			}
		}
		
		.DirectionTable {
			margin-bottom: 60px;
			
			.container > .container {
				display: none;
				width: 0;
			}
		}
	}
	
	// PC
	@media (min-width: 1024px) {
		.container {
			padding-top: 65px;
			padding-bottom: 80px;
			
			#map {
				height: 450px;
			}
			
			.DirectionTable {
				margin-top: 85px;
				
				span {
					padding: 15px;
					width: 180px;
				}
				
				.container {
					width: calc(100% - 180px);
					padding: 0;
					
					span {
						width: 100%;
						border-right: none;
					}
				}
			}
			
			.LinkWrap {
				a {
					padding: 25px 60px;
				}
			}
		}
	}
	
	// Tablet
	@media (max-width: 1023px) and (min-width: 426px) {
		.container {
			padding: 95px 0 60px 0;
			
			#map {
				height: 45.572vw;
			}
			
			.DirectionTable {
				margin-top: 12.369vw;
				
				span {
					padding: 15px;
					width: 130px;
					font-size: 11px;
				}
				
				a {
					font-size: 11px;
				}
				
				.container {
					width: calc(100% - 130px);
					padding: 0;
					
					span {
						width: 100%;
						border-right: none;
					}
				}
			}
			
			.LinkWrap {
				a {
					padding: 2.604vw 6.510vw;
					font-size: 11px;
				}
			}
		}
	}
	
	// Mobile
	@media (max-width: 425px) {
		.container {
			padding: 45px 0 65px 0;
			
			#map {
				height: 66.666vw;
			}
			
			.DirectionTable {
				margin-top: 8vw;
				word-break: keep-all;
				
				span {
					padding: 15px;
					width: 100px;
					font-size: 11px;
				}
				
				a {
					font-size: 11px;
					word-break: break-word;
				}
				
				.container {
					width: calc(100% - 100px) !important;
					padding: 0;
					
					span {
						width: 100%;
						border-right: none;
					}
				}
				
				#tel .container span {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}
				
			}
			
			.LinkWrap {
				a {
					padding: 5vw 20vw;
				}
			}
		}
	}
}