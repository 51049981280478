@import "src/css/global";

:global #Register {
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.6);
	color: white;
}

.bgImg {
	height: 130%;
	width: 100%;
	object-fit: cover;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
}

.container {
	padding: 150px 0;
	.title {
		text-align: center;
		font-family: "GreycliffCF", sans-serif;
	}
	
	.form {
		display: flex;
		flex-direction: column;
		
		.label {
			font-size: 14px;
			font-weight: $light;
			
			b {
				color: $sungyun-red;
				font-size: inherit;
			}
		}
		
		.input {
			padding: 25px 15px;
			width: 100%;
			
			background-color: white;
			border: none;
			
			font-weight: $light;
			
			color: black;
		}
		
		.agreePolicyText {
			display: flex;
			align-items: center;
			font-weight: $light;
			
			.checkBoxButton {
				border-radius: 5px;
				border: solid 1px white;
				transition: background-color .3s ease;
			}
			
			.checkbox {
				display: none;
			}
			
			.checkbox:checked ~ .checkBoxButton {
				background-color: $sungyun-red;
			}
		}
		
		.button {
			color: white;
			border: none;
			font-weight: $bold;
			background-color: $sungyun-red;
			transition: background-color .3s ease;
			cursor: pointer;
			
			&:hover {
				background-color: rgba($sungyun-red, .6);
			}
		}
	}
}

// PC
@media (min-width: 1024px) {
	.container {
		width: 430px;
		padding: 150px 0;
		
		.title {
			font-size: 72px;
			margin-bottom: 100px;
		}
		
		.form {
			
			.label {
				font-size: 14px;
				margin-bottom: 20px;
			}
			
			.input {
				font-size: 18px;
				margin-bottom: 20px;
			}
			
			.agreePolicyText {
				font-size: 14px;
				margin-bottom: 20px;
				
				.checkBoxButton {
					width: 22px;
					height: 22px;
					margin-right: 15px;
				}
			}
			
			.button {
				padding: 22px 0;
				font-size: 18px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	:global #Register {
		padding: tablet(250px) 0 tablet(160px) 0;
	}
	
	.container {
		width: tablet(560px);
		
		.title {
			font-size: tablet(72px);
			margin-bottom: tablet(100px);
		}
		
		.form {
			.label, .input {
				font-size: tablet(18px);
				margin-bottom: tablet(20px);
			}
			
			.agreePolicyText {
				font-size: tablet(14px);
				margin-bottom: tablet(20px);
				
				.checkBoxButton {
					width: tablet(22px);
					height: tablet(22px);
					margin-right: tablet(15px);
				}
			}
			
			.button {
				padding: tablet(22px) 0;
				font-size: tablet(18px);
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	:global #Register {
		padding: mobile(100px) 0 mobile(75px) 0;
	}
	
	.container {
		width: mobile(290px);
		
		.title {
			font-size: mobile(38px);
			margin-bottom: mobile(80px);
		}
		
		.form {
			.label, .input {
				font-size: mobile(13px);
			}
			
			.label {
				margin-bottom: mobile(10px);
			}
			
			.input {
				margin-bottom: mobile(15px);
			}
			
			.agreePolicyText {
				font-size: mobile(10px);
				margin-bottom: mobile(15px);
				
				.checkBoxButton {
					width: mobile(17px);
					height: mobile(17px);
					margin-right: mobile(12.5px);
				}
			}
			
			.button {
				padding: mobile(15px) 0;
				font-size: mobile(13px);
			}
		}
	}
}