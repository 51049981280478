@import "src/css/global";
.container {
	padding: 100px 0;
	
	.block {
		padding: 15px 0;
		
		&:not(:first-child) {
			margin-top: 38px;
		}
		
		h1 {
			font-size: 40px;
			line-height: 1;
		}
		
		h3 {
			font-size: 20px;
			line-height: 1.8;
		}
		
		p {
			font-size: 15px;
			margin-top: 25px;
			line-height: 1.8;
			font-weight: $light;
		}
		
		ul {
			padding: 0 30px;
			
			li {
				font-size: 15px;
			}
		}
	}
}