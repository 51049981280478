@import "src/css/global";

#ProductBar {
	display: flex;
	align-items: center;
	
	input[type="range"], button { cursor: pointer; }
	
	input[type="range"] {
		-webkit-appearance: none;
		width: 100%;
		height: 19px;
		
		@mixin sliderThumb {
			-webkit-appearance: none;
			height: 2px;
			border: none;
			border-radius: 0;
			background: #000;
		}
		
		@mixin sliderTrack {
			width: 100%;
			height: 1px;
			background: #BDBDBD;
		}
		
		&::-webkit-slider-thumb { @include sliderThumb; }
		&::-moz-range-thumb { @include sliderThumb; }
		
		&::-webkit-slider-runnable-track { @include sliderTrack; }
		&::-moz-range-track { @include sliderTrack; }
	}
	
	button {
		appearance: none;
		border: none;
		background-color: transparent;
		
		svg { font-size: 30px; }
	}
	
	// PC
	@media screen and (min-width: 1024px) {
		margin-top: 80px;
		
		label {
			width: calc(100% - 140px);
		}
		
		.btnMinus, .btnPlus { margin-left: 40px; }
	}
	
	// Tablet
	@media screen and (max-width: 1023px) and (min-width: 425px) {
		margin-top: 50px;
		
		label {
			width: calc(100% - 160px);
		}
		
		.btnMinus, .btnPlus { margin-left: 50px; }
	}
	
	// Mobile
	@media screen and (max-width: 425px) {
		margin-top: 27px;
		
		label {
			width: calc(100% - 110px);
		}
		
		.btnMinus { margin-right: 25px; }
		.btnPlus { margin-left: 25px }
	}
}