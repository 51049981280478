@import "src/css/global";

.item {
	width: 100%;
	border-top: solid 2px black;
	border-bottom: solid 1px #7F7F7F;
	background-color: #F7F7F7;
	font-weight: $bold;
}

.type {
	display: none;
}

@media (min-width: 768px) {
	.item {
		padding: 15px 25px;
		margin-bottom: 15px;
	}
}

// Tablet
@media (max-width: 767px) and (min-width: 426px) {
	.item {
		padding: 15px 25px;
		margin-bottom: 20px;
		font-size: 13px;
	}
}

// Mobile
@media (max-width: 425px) {
	.item {
		padding: 15px 12.5px;
		margin-bottom: 15px;
		font-size: 10px;
	}
}