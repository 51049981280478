@import "src/css/global";

.link {
	display: inline-block;
	width: 100%;
	margin-right: 0;
	
	.row {
		display: flex;
		
		.column {
			border-bottom: solid 1px #93929B;
			font-weight: $light;
			
			.isAnswered {
				background-color: $sungyun-indigo;
				color: white;
				padding: 2.5px 25px;
				border-radius: 500px;
				margin-left: 15px;
				
				&.false {
					background-color: $sungyun-red;
				}
			}
			
			&:not(:last-child) {
				border-right: solid 1px #7F7F7F;
			}
		}
	}
}

// PC
@media (min-width: 1024px) {
	.row {
		.column {
			padding: 15px 25px;
			
			&:nth-child(1) {
				flex: 0 1 75px;
			}
			
			&:nth-child(2) {
				flex: 0 1 150px;
			}
			
			&:nth-child(3) {
				flex: 0 0 calc(100% - 350px);
			}
			
			&:nth-child(4) {
				flex: 0 1 125px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.row {
		font-size: 18px;
		
		.column {
			padding: tablet(15px) tablet(25px);
			
			&:nth-child(1) {
				width: 10%;
			}
			
			&:nth-child(2) {
				display: none;
			}
			
			&:nth-child(3) {
				width: 70%;
			}
			
			&:nth-child(4) {
				width: 20%
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.row {
		font-size: 13px;
		.column {
			padding: tablet(30px) tablet(25px);
			border-right: none !important;
			
			&:nth-child(1) {
				display: none;
			}
			
			&:nth-child(2) {
				display: none;
			}
			
			&:nth-child(3) {
				width: 100%;
			}
			
			&:nth-child(4) {
				display: none;
			}
		}
	}
}