@import "src/css/global";

.inputContainer {
	border-top: solid 2px black;
}

.textContainer {
	text-align: right;
	
	a {
		font-weight: $bold;
		color: $sungyun-red;
	}
}

.buttonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	
	button {
		background: transparent;
		border: solid 1px #A8ACAF;
		border-radius: 5px;
		color: #A8ACAF;
		cursor: pointer;
	}
}

// PC
@media (min-width: 1024px) {
	.inputContainer {
		margin-bottom: 30px;
	}
	
	.form {
		padding: 100px 0;
	}
	
	.buttonContainer {
		button {
			padding: 20px 30px;
			
			&:not(:last-child) {
				margin-right: 35px;
			}
		}
	}
}

// Tablet
@media (max-width: 1023px) and (min-width: 426px) {
	.inputContainer {
		margin-bottom: tablet(50px);
	}
	
	.form {
		padding: tablet(50px) 0;
	}
	
	.buttonContainer {
		margin-top: tablet(50px);
		
		button {
			padding: tablet(20px) tablet(50px);
			
			&:not(:last-child) {
				margin-right: tablet(30px);
			}
		}
	}
}

// Mobile
@media (max-width: 425px) {
	.inputContainer {
		margin-bottom: mobile(50px);
	}
	
	.form {
		padding: mobile(50px) 0;
	}
	
	.buttonContainer {
		margin-top: mobile(50px);
		
		button {
			padding: mobile(15px) mobile(45px);
			
			&:not(:last-child) {
				margin-right: mobile(12px);
			}
		}
	}
}